import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Seo from 'common/Seo';
import { FullArticle } from 'components/Article';
import ColorTopBar from 'components/ColorTopBar';

import { ArticleTypes } from '../../@types/content/article';

const ArticlePage: FC<ArticleTypes.ArticlePageProps> = ({
  data: {
    articlePage: { urls, seo, article },
  },
}) => (
  <Layout headerTransparent>
    <Seo {...{ urls, seo }} />
    <ColorTopBar />
    <FullArticle article={article} />
  </Layout>
);

export const query = graphql`
  query ($url: String, $lang: String) {
    articlePage(url: { eq: $url }, lang: { eq: $lang }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      article {
        ...ArticleFragment
      }
    }
  }
`;

export default ArticlePage;
