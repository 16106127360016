import React, { FC } from 'react';
import { Link } from 'gatsby';

import Image from 'common/Image';

import { ArticleTileProps } from './models';

import './ArticleTile.scss';

const ArticleTile: FC<ArticleTileProps> = ({ article: { url, title, titleHeader, image } }) => (
  <div className="article-tile">
    <Link to={url}>
      <Image className="article-tile__image" imageData={image} alt={title} />
      <h3 className="article-tile__title">
        {titleHeader}
        <br />
        {title}
      </h3>
    </Link>
  </div>
);

export default ArticleTile;
