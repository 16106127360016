import React, { FC } from 'react';

import { Container } from 'layout';
import { ArticleTile } from 'components/Article/index';

import { AllArticlesProps } from './models';

import './AllArticles.scss';

const AllArticles: FC<AllArticlesProps> = ({ articles, allArticlesHeadingText }) => (
  <section className="all-articles">
    <Container className="all-articles__container">
      <h2>{allArticlesHeadingText}</h2>
      {articles?.map((article) => (
        <ArticleTile key={article.url} article={article} />
      ))}
    </Container>
  </section>
);

export default AllArticles;
