import React, { FC } from 'react';
import { Link } from 'gatsby';

import { Container, DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import { VisualType, VisualVariant } from 'common/Button/enums';
import Image from 'common/Image';

import { FullArticleProps } from './models';

import './FullArticle.scss';

const FullArticle: FC<FullArticleProps> = ({
  article: {
    titleHeader,
    title,
    image,
    description,
    createDate,
    publicationText,
    discoverAlsoText,
    discoverAlsoLinks,
  },
}) => (
  <section className="full-article">
    <Container>
      <span className="full-article__title">{titleHeader}</span>
      <h1 className="full-article__title">{title}</h1>
      <span className="full-article__date">{`${publicationText} ${createDate}`}</span>
      <Image className="full-article__image" imageData={image} alt={title} />
      <DangerouslySetInnerHtml className="full-article__description" html={description} />
      {!!discoverAlsoText && !!discoverAlsoLinks?.length && (
        <>
          <p className="full-article__text-also">{discoverAlsoText}</p>
          {discoverAlsoLinks.map(({ url, name }) => (
            <div className="full-article__button-container" key={url}>
              <Link to={url}>
                <Button
                  visualType={VisualType.Rounded}
                  visualVariant={VisualVariant.ReversedPink}
                  ariaLabel={name}
                >
                  {name}
                </Button>
              </Link>
            </div>
          ))}
        </>
      )}
    </Container>
  </section>
);

export default FullArticle;
