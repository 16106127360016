import React, { FC } from 'react';

import { Container } from 'layout';
import { ArticleTile } from 'components/Article/index';

import { NewArticlesProps } from './models';

import './NewArticles.scss';

const NewArticles: FC<NewArticlesProps> = ({ articles, newArticleHeadingText }) => (
  <section className="new-articles">
    <Container className="new-articles__container">
      <h2>{newArticleHeadingText}</h2>
      {articles?.map((article) => (
        <ArticleTile key={article.url} article={article} />
      ))}
    </Container>
  </section>
);

export default NewArticles;
