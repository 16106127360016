import React, { FC } from 'react';

import { Container } from 'layout';
import { ArticleTile } from 'components/Article/index';

import { EssentialArticlesProps } from './models';

import './EssentialArticles.scss';

const EssentialArticles: FC<EssentialArticlesProps> = ({
  articles,
  essentialArticlesHeadingText,
}) => (
  <section className="essential-articles">
    <Container>
      <h2>{essentialArticlesHeadingText}</h2>
      {articles?.map((article) => (
        <ArticleTile key={article.url} article={article} />
      ))}
    </Container>
  </section>
);

export default EssentialArticles;
